import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { catchError, EMPTY, map, Observable, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ScrollManagerDirective } from '../shared/utils/directives/scroll-manager.directive';
import { DynamicLoadDirective } from '../shared/directives/dynamic-load.directive';
import { RoutesEnum } from '../models/routes.enum';
import { DynamicSection } from '../models/dynamic-section';
import { TypePageFields } from '../models/contentful-content-types/page';
import { ContentfulApiService } from '../shared/services/contentful/contentful-api.service';
import { MetadataService } from '../shared/services/metadata/metadata.service';
import { ContentfulService } from '../shared/services/contentful/contentful.service';
import { I18nService } from '../shared/services/localization/i18n.service';

@Component({
  template: `
    <ng-container *ngIf="sections$ | async as sections">
      <div appScrollManager>
        <ng-template
          *ngFor="let section of sections"
          [appDynamicLoad]="section"
        >
        </ng-template>
      </div>
    </ng-container>
  `,
  standalone: true,
  imports: [CommonModule, DynamicLoadDirective, ScrollManagerDirective],
  providers: [ContentfulApiService],
})
export class DynamicPageComponent implements OnInit {
  sections$!: Observable<DynamicSection[]>;

  constructor(
    readonly router: Router,
    readonly i18nService: I18nService,
    readonly contentfulService: ContentfulService,
    readonly metadataService: MetadataService,
    readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.url.subscribe(() => this.getSections());
  }

  getSections(): void {
    this.sections$ = this.contentfulService
      .getPage(
        this.i18nService.urlWithoutLanguage,
        'de-DE'
      )
      .pipe(
        tap((page) => this.metadataService.setMetadata(page.metadata?.fields)),
        map((page) => this.mapSectionsAndDataFromPage(page)),
        catchError(() => {
          this.router.navigate([RoutesEnum.NotFound]);
          return EMPTY;
        })
      );
  }

  mapSectionsAndDataFromPage(page: TypePageFields): DynamicSection[] {
    const sections = page.sections || [];
    return sections.map((section) => {
      const { sys, fields } = section;
      return {
        id: sys.contentType.sys.id,
        data: fields,
      };
    });
  }
}
