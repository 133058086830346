import { Directive, Injector, Input, ViewContainerRef } from '@angular/core';
import { DynamicSection } from '../../models/dynamic-section';
import { SECTIONS } from '../../models/sections';
import { SectionDynamicComponent } from '../../models/section-component';

@Directive({
  selector: '[appDynamicLoad]',
  standalone: true
})
export class DynamicLoadDirective {
  @Input('appDynamicLoad')
  set dynamicLoad(dynamicSection: DynamicSection) {
    this.loadSection(dynamicSection);
  }

  constructor(readonly viewContainerRef: ViewContainerRef, readonly inject: Injector) {}

  private loadSection(section: DynamicSection): void {
    if (this.existsSectionModule(section.id)) {
      this.createSection(section);
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Section ${section.id} does not exist`);
    }
  }

  private existsSectionModule(sectionId: string): boolean {
    return SECTIONS.has(sectionId);
  }

  private async createSection(section: DynamicSection): Promise<void> {
    const { id, data } = section;
    const sectionComponent = SECTIONS.get(id);
    if (sectionComponent) {
      const component = await sectionComponent();

      this.viewContainerRef.clear();
      if (!component) {
        throw new Error(`Module ${id} does not have a static component property defined`);
      }
      const componentRef = this.viewContainerRef.createComponent<SectionDynamicComponent>(component);
      componentRef.instance['data'] = data;
      componentRef.hostView.detectChanges();
    }
  }
}
